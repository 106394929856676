<template>
  <div class="main_wrapper_app_content">
    <v-row>
      <v-col cols="3">
        <v-card class="p-3">
          <v-card-title>
            Online Users <span class="realtimeSignal heartbreak ml-2"></span>
          </v-card-title>
          <v-card-subtitle>
            Realtime Online Users on Gowawe
          </v-card-subtitle>
          <v-card-text>
            <h1>{{ onlineUsers.length }}</h1>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-title>
            Online Members <span class="realtimeSignal heartbreak ml-2"></span>
          </v-card-title>
          <v-card-subtitle>
            Realtime Online Member on Gowawe
          </v-card-subtitle>
          <v-card-text>
            <h1>{{ onlineMembers.length }}</h1>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="p-3">
          <v-sheet color="transparent">
            <v-sparkline
              :smooth="3"
              :gradient="['#f72047', '#ffd200', '#1feaea']"
              :line-width="2"
              :height="180"
              :width="500"
              :show-labels="true"
              :labels="values"
              :value="values"
              auto-draw
              stroke-linecap="round"
            ></v-sparkline>
          </v-sheet>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="py-5 px-1">
          <v-card-title>
            Statistics <span class="realtimeSignal heartbreak ml-2"></span>
          </v-card-title>
          <v-card-subtitle>
            User statistics
          </v-card-subtitle>
          <v-card-text>
            <v-layout wrap justify-space-around>
              <v-col cols="12">
                <b>Created at:</b>
                {{ moment(valuesCounter.created_at).format("hh:mm:ss") }}</v-col
              >
              <v-col cols="12"> <b>Now:</b> {{ nowTime }}</v-col>
              <v-col cols="12">
                <b>Passing time:</b>
                {{ moment(valuesCounter.created_at).fromNow() }}</v-col
              >
            </v-layout>
            <v-layout wrap justify-space-around>
              <v-col> <b>Maximum:</b> {{ valuesCounter.max }}</v-col>
              <v-col> <b>Minimum:</b> {{ valuesCounter.min }}</v-col>
              <v-col> <b>Timer:</b> {{ valuesCounter.timer }}</v-col>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="py-3">
          <v-card-title>
            Members Data

            <v-chip
              class="ma-2"
              dark
              color="green"
              v-on:click="activeTableTab = 0"
            >
              Online
            </v-chip>
            <v-chip
              class="ma-2"
              :close="activeTableTab == 1"
              color="primary"
              close-icon="mdi-refresh"
              v-on:click="activeTableTab = 1"
              @click:close="$refs.homehistory.getDataFromApi()"
            >
              History
            </v-chip>
          </v-card-title>
          <v-card-subtitle>
            Realtime Online Members Data on Gowawe
          </v-card-subtitle>

          <v-simple-table v-if="activeTableTab == 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    User ID
                  </th>
                  <th class="text-left">
                    Avatar
                  </th>
                  <th class="text-left">
                    User Name
                  </th>
                  <th class="text-left">
                    Phone Number
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    URL
                  </th>
                  <th class="text-left">
                    Registration Country
                  </th>
                  <th class="text-left">
                    Type
                  </th>
                  <th class="text-left">
                    Time
                  </th>
                  <th class="text-left">
                    Moment
                  </th>
                  <th class="text-left">
                    Statu
                  </th>
                  <th class="text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in onlineMembers" :key="'on-' + index">
                  <td>{{ item.data.id }}</td>
                  <td class="py-2">
                    <v-avatar max-height="60" max-width="60">
                      <v-img :src="item.data.image"></v-img>
                    </v-avatar>
                  </td>
                  <td>
                    <v-btn
                      small
                      elevation="0"
                      link
                      :to="'/members/edit/' + item.data.memberid"
                      >{{ item.data.name }}</v-btn
                    >
                  </td>
                  <td>{{ item.data.phone }}</td>
                  <td>{{ item.data.email }}</td>
                  <td>{{ item.data.url }}</td>
                  <td><div v-html="matchCountry(item.data.country)"></div></td>
                  <td>
                    <v-chip
                      :color="
                        item.data.is_buyer == '' && item.data.is_seller == '1'
                          ? 'success'
                          : item.data.is_buyer == '1' &&
                            item.data.is_seller == '1'
                          ? 'secondary'
                          : 'error'
                      "
                    >
                      {{
                        item.data.is_buyer == "" && item.data.is_seller == "1"
                          ? "Seller"
                          : item.data.is_buyer == "1" &&
                            item.data.is_seller == "1"
                          ? "Both"
                          : "Buyer"
                      }}
                    </v-chip>
                  </td>
                  <td>{{ moment(item.data.time).format("hh:mm:ss") }}</td>
                  <td>{{ moment(item.data.time).fromNow() }}</td>
                  <td><span class="realtimeSignal"></span></td>
                  <td>
                    <v-btn
                      elevation="0"
                      link
                      color="primary"
                      :to="'/members/edit/' + item.data.memberid + '/12'"
                      ><v-icon>mdi-eye-arrow-right</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div v-if="activeTableTab == 1">
            <homehistory :countries="countries" ref="homehistory"></homehistory>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="py-3">
          <v-card-title>
            Users Data
          </v-card-title>
          <v-card-subtitle>
            Realtime Online Users Data on Gowawe
          </v-card-subtitle>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Flag
                  </th>
                  <th class="text-left">
                    City
                  </th>
                  <th class="text-left">
                    Country
                  </th>
                  <th class="text-left">
                    Timezone
                  </th>
                  <th class="text-left">
                    Browser
                  </th>
                  <th class="text-left">
                    Is Mobile
                  </th>
                  <th class="text-left">
                    Internet
                  </th>
                  <th class="text-left">
                    Continent
                  </th>
                  <th class="text-left">
                    IP
                  </th>
                  <th class="text-left">
                    Currency
                  </th>
                  <th class="text-left">
                    Date - Time
                  </th>
                  <th class="text-left">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody v-if="2 == 1">
                <tr v-for="(item, index) in onlineUsers" :key="index">
                  <td>
                    <v-img
                      max-height="30"
                      max-width="30"
                      :src="item.data.location.country.flag.emojitwo"
                    ></v-img>
                  </td>
                  <td>{{ item.data.location.city }}</td>
                  <td>{{ item.data.location.country.name }}</td>
                  <td>{{ item.data.time_zone.id }}</td>
                  <td>{{ item.data.user_agent.name }}</td>
                  <td>{{ item.data.isMobile ? "Yes" : "No" }}</td>
                  <td>{{ item.data.company.name }}</td>
                  <td>
                    {{
                      item.data.location.continent.name +
                        " - " +
                        item.data.location.continent.code
                    }}
                  </td>
                  <td>{{ item.data.ip }}</td>
                  <td>
                    {{
                      item.data.currency.code +
                        " " +
                        item.data.currency.symbol_native
                    }}
                  </td>
                  <td>{{ item.data.today + " " + item.data.now }}</td>
                  <td>
                    <v-btn
                      color="purple"
                      disabled
                      v-on:click="(actionUser = item), (sheet = true)"
                    >
                      <v-icon>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Connection.. Please wait.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import HomeHistory from "../Components/Home/History.vue";

export default {
  name: "Home",
  components: {
    homehistory: HomeHistory,
  },
  data() {
    return {
      activeTableTab: 0,
      moment: moment,
      labels: [],
      values: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
      nowTime: 0,
      valuesStorage: [],
      valuesCounter: {
        min: 0,
        max: 0,
        timer: 1,
        created_at: new Date(),
      },
      maxCount: 0,
      dialog: false,
      token: localStorage.getItem("authToken"),
      onlineUsers: [],
      onlineMembers: [],
      actionUser: {
        data: {
          uniqueId: null,
        },
      },
      sheet: false,
      countries: [],
      configData: {
        id: null,
        data: [
          {
            type: "watch",
            statu: false,
          },
        ],
      },
    };
  },
  methods: {
    action: function() {
      this.dialog = true;
      this.configData.id = this.actionUser.data.uniqueId;
      this.configData.data.find((el) => el.type == "watch").statu = true;
      this.$socket.emit("vuepanel", this.configData);
    },
    getCountry: function() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "country/filter?page=1&per_page=1000&api_token=" +
            this.token
        )
        .then((res) => {
          res.data.data.data.forEach((item) => {
            this.countries.push({
              id: item.id,
              name: item.name,
              country_code: item.country_code,
            });
          });
        });
    },
    matchCountry(id) {
      if (this.countries.find((a) => a.id == Number(id))) {
        let cu = this.countries.find((a) => a.id == Number(id));
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  sockets: {
    userCount: (data) => {
      // data
    },
    usersGowaweSet: (data) => {
      // data
    },
  },
  mounted() {
    this.sockets.subscribe("usersGowaweSet", (data) => {
      this.onlineMembers = data;
    });
    this.sockets.subscribe("userCount", (data) => {
      this.onlineUsers = data.reverse();
      this.values.push(this.onlineUsers.length);
      this.valuesStorage.push(this.onlineUsers.length);

      if (this.values.length > 25) {
        this.values.splice(0, 1);
      }
      const xvalues = this.valuesStorage;
      this.valuesCounter.max = Math.max(...xvalues);
      this.valuesCounter.min = Math.min(...xvalues);
    });

    this.getCountry();

    setInterval(() => {
      this.valuesCounter.timer++;
      if (this.valuesCounter.timer > 5) {
        this.valuesCounter.timer = 1;
      }

      this.nowTime = this.moment(new Date()).format("hh:mm:ss");
    }, 1000);

    // console.log(this.user);
  },
};
</script>

<style>
.realtimeSignal {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #2acf00;
  display: block;
}
.realtimeSignal.heartbreak {
  animation: signal 1s ease-in-out infinite;
  -webkit-animation: signal 1s ease-in-out infinite;
}
@keyframes signal {
  0% {
    background: #fff;
  }
  30% {
    background: #2acf00;
  }
  70% {
    background: #2acf00;
  }
  100% {
    background: #fff;
  }
}
</style>
