<template>
  <div class="table_wrapper">
    <v-card flat>
      <v-layout wrap align-center>
        <v-col cols="12" sm="6" md="4" lg="4" xl="3">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Select Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" xl="3">
          <v-select
            v-model="filter.type"
            label="Select Type"
            item-value="id"
            item-text="name"
            :items="types"
          ></v-select>
        </v-col>
        <v-btn
          color="teal"
          dark
          elevation="0"
          small
          v-on:click="getDataFromApi"
          :loading="loading"
        >
          <v-icon class="mr-2" small>
            mdi-account-filter
          </v-icon>
          Apply Filter
        </v-btn>
      </v-layout>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:[`item.c5`]="{ item }">
        <div v-html="item.c5"></div>
      </template>

      <template v-slot:[`item.c6`]="{ item }">
        <v-badge :content="item.c6" color="primary"></v-badge>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              link
              v-bind="attrs"
              v-on="on"
              color="primary"
              :to="'/members/edit/' + item.item.interaction_member.id + '/12'"
            >
              <v-icon>mdi-eye-arrow-right</v-icon>
            </v-btn>
          </template>
          <span>Logs</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "HomeHistory",
  props: {
    countries: {
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
      date: [],
      filter: {
        start_date: null,
        end_date: null,
        type: null,
      },
      types: [
        {
          id: "all",
          name: "All",
        },
        {
          id: "seller",
          name: "Seller",
        },
        {
          id: "buyer",
          name: "Buyer",
        },
      ],
      modal: false,
      dialog: false,
      options: {},
      search: "",
      loading: true,
      countriesAr: [],
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "User ID", value: "c1", sortable: false },
        { text: "Username", value: "c2", sortable: false },
        { text: "Phone Number", value: "c3", sortable: false },
        { text: "Email", value: "c4", sortable: false },
        { text: "Registration Country", value: "c5", sortable: false },
        { text: "Number of Visit", value: "c6", sortable: false },
        { text: "Moment", value: "c7m", sortable: false },
        { text: "Last Visit Date", value: "c7", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
      timer: null,
      dialogAction: {
        url: "",
        data: null,
        type: "",
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    countries(newVal, oldVal) {
      this.countriesAr = newVal;
    },
  },
  methods: {
    matchCountry(id) {
      if (this.countries.find((a) => a.id == Number(id))) {
        let cu = this.countries.find((a) => a.id == Number(id));
        return (
          '<img src="https://gowawe.com/img/flags/shiny/64/' +
          cu.country_code +
          '.png" width="20" /> <span>' +
          cu.name +
          "</span>"
        );
      }
    },
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    getDataFromApi() {
      this.loading = true;

      this.fakeApiCall().then((data) => {
        this.desserts = [];

        data.itemsR.forEach((item, index) => {
          const key = {
            c1: item.interaction_user.id,
            c2:
              item.interaction_member.first_name +
              " " +
              item.interaction_member.last_name,
            c3:
              "+" +
              item.interaction_member.country_code +
              " " +
              item.interaction_member.phone,
            c4: item.interaction_user.email,
            c5: this.matchCountry(item.interaction_member.country_id),
            c6: item.number_of_visits,
            c7m: this.moment(item.updated_at).fromNow(),
            c7: item.updated_at,
            actions: null,
            id: item.id,
            item: item,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    async getDesserts() {
      let url = process.env.VUE_APP_API_URL + "daily-visiters";

      if (this.date.length == 1) {
        this.filter.start_date = this.date[0];
        this.filter.end_date = null;
      }

      if (this.date.length == 2) {
        this.filter.start_date = this.date[0];
        this.filter.end_date = this.date[1];
      }

      try {
        const response = await axios.get(url, {
          params: {
            page: this.page,
            per_page: this.perPage,
            start_date: this.filter.start_date,
            end_date: this.filter.end_date,
            member_type: this.filter.type,
          },
        });
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style scoped>
.store_link {
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  padding: 0 5px;
}
</style>
